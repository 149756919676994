import { FC, useEffect, useState } from 'react';
import {
  Text,
  Stack,
  StackItem,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
} from '@fluentui/react';
import {
  DocumentLogEntry,
  MigrationLogEntry,
  ProcessImportArtifactsResponse,
} from '../../Models/API/IArtifactsJobResponse';

export interface IImportArtifactReportProps {
  reportResults: string | null;
}

const ImportArtifactReport: FC<IImportArtifactReportProps> = ({ reportResults }) => {
  const [report, setReport] = useState<ProcessImportArtifactsResponse | null>(null);
  const [selectedLogEntry, setSelectedLogEntry] = useState<MigrationLogEntry | null>(null);

  useEffect(() => {
    if (reportResults) {
      const parsedReport = JSON.parse(reportResults);
      setReport(parsedReport);
      setSelectedLogEntry(parsedReport.MigrationLogEntries[0]);
    }
  }, [reportResults]);

  // Columns for the document log entries table
  const documentLogColumns: IColumn[] = [
    {
      key: 'documentName',
      name: 'Document Name',
      fieldName: 'DocumentName',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'success',
      name: 'Success',
      fieldName: 'Success',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: DocumentLogEntry) => (item.Success ? 'Yes' : 'No'),
    },
    {
      key: 'errorMessage',
      name: 'Error Message',
      fieldName: 'ErrorMessage',
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: DocumentLogEntry) => item.ErrorMessage || 'N/A',
    },
  ];

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }} styles={{ root: { padding: 20 } }}>
      <StackItem styles={{ root: { width: '300px' } }}>
        {report && (
          <Stack>
            <Text variant='large' block>
              <strong>Import Summary</strong>
            </Text>
            <Text style={{ marginBottom: '5px' }}>
              Total Libraries Created: {report.Summary.TotalLibrariesCreated}
            </Text>
            <Text style={{ marginBottom: '5px' }}>
              Total Libraries Recreated: {report.Summary.TotalLibrariesRecreated}
            </Text>
            <Text style={{ marginBottom: '5px' }}>
              Total Libraries Updated: {report.Summary.TotalLibrariesUpdated}
            </Text>
            <Text style={{ marginBottom: '5px' }}>
              Total Libraries Not in Source: {report.Summary.TotalLibrariesNotInSource}
            </Text>
            <Text style={{ marginBottom: '5px' }}>
              Total Documents Processed: {report.Summary.TotalDocumentsProcessed}
            </Text>
            <Text style={{ marginBottom: '5px' }}>
              Total Documents Failed: {report.Summary.TotalDocumentsFailed}
            </Text>

            <Stack tokens={{ childrenGap: 10 }} styles={{ root: { marginTop: 20 } }}>
              <Text variant='large' block>
                <strong>Migration Log Entries</strong>
              </Text>
              {report.MigrationLogEntries.filter(logEntry => logEntry.Action !== 'NotInSource').map(
                (logEntry, index) => (
                  <Text
                    key={index}
                    variant='medium'
                    onClick={() => setSelectedLogEntry(logEntry)}
                    styles={{
                      root: {
                        cursor: 'pointer',
                        textDecoration: selectedLogEntry === logEntry ? 'underline' : 'none',
                      },
                    }}
                  >
                    {logEntry.LibraryTitle} ({logEntry.Action} -{' '}
                    {logEntry.Success ? 'Success' : 'Failed'})
                  </Text>
                )
              )}
            </Stack>
          </Stack>
        )}
      </StackItem>

      <StackItem grow={3} styles={{ root: { width: 'calc(100% - 320px)' } }}>
        {selectedLogEntry ? (
          <Stack>
            <Text variant='large' block>
              <strong>Migration Log Entry Details for {selectedLogEntry.LibraryTitle}</strong>
            </Text>
            <Stack>
              <Text style={{ marginBottom: '5px' }}>Action: {selectedLogEntry.Action}</Text>
              <Text style={{ marginBottom: '5px' }}>
                Success: {selectedLogEntry.Success ? 'Yes' : 'No'}
              </Text>
              {selectedLogEntry.ErrorMessage && (
                <Text>Error Message: {selectedLogEntry.ErrorMessage}</Text>
              )}
              <Text style={{ marginBottom: '5px' }}>
                Documents Processed: {selectedLogEntry.DocumentProcessedCount}
              </Text>
              <Text style={{ marginBottom: '5px' }}>
                Documents Failed: {selectedLogEntry.DocumentFailedCount}
              </Text>
            </Stack>

            <Text variant='large' block style={{ marginTop: '15px' }}>
              <strong>Document Log for {selectedLogEntry.LibraryTitle}</strong>
            </Text>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <DetailsList
                items={selectedLogEntry.DocumentLogEntries}
                columns={documentLogColumns}
                setKey='set'
                layoutMode={DetailsListLayoutMode.fixedColumns}
                selectionMode={SelectionMode.none}
              />
            </div>
          </Stack>
        ) : (
          <Text variant='large' block>
            Select a migration log entry to view its document details.
          </Text>
        )}
      </StackItem>
    </Stack>
  );
};

export default ImportArtifactReport;
