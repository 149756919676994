import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ChatServiceContext } from '../../Services/API/ChatService';
import { chatAppServiceContext } from '../../Services/API/ChatAppService';
import {
  Stack,
  Spinner,
  SpinnerSize,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DialogType,
  IconButton,
  IButtonStyles,
  Modal,
  mergeStyleSets,
  FontWeights,
  IIconProps,
  ProgressIndicator,
} from '@fluentui/react';
import {
  faPaperPlane,
  faArrowsRotate,
  faQuestion,
  faWarning,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IChatMessage } from '../../Models/API/IConversation';
import AuthenticationService from '../../Services/AuthenticationService';
import IChatApp from '../../Models/API/IChatApp';
import './ApplicationChatDebug.scss';
import { AppCatalogServiceContext } from '../../Services/AppCatalogService';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import ConversationDebug from '../ConversationDebug/ConversationDebug';
import ChatFiles from '../ChatFiles/ChatFiles';
import { IPickerDocument } from '../DocumentPicker/DocumentPicker';
import { VALID_UPLOAD_FILE_EXTENSIONS } from '../../Pages/CatalogsEditor/Catalog/Applications/ChatApps/Libraries/Library/Documents/Documents';
import { DialogServiceContext } from '../../Services/Dialogs/DialogService';
import axios, { AxiosError, AxiosProgressEvent, CancelTokenSource } from 'axios';
import { UserServiceContext } from '../../Services/API/UserService';
import TextareaAutosize from 'react-textarea-autosize';

const cancelIcon: IIconProps = { iconName: 'Cancel' };

export interface IApplicationChatDebugProps {
  chatApp: IChatApp;
  showDescription?: boolean;
  maxTokens: number;
}

const ApplicationChatDebug: FC<IApplicationChatDebugProps> = ({
  chatApp,
  showDescription = false,
  maxTokens,
}) => {
  const chatAppsService = useContext(chatAppServiceContext);
  const chatService = useContext(ChatServiceContext);
  const appCatalogService = useContext(AppCatalogServiceContext);
  const userService = useContext(UserServiceContext);
  const theme = appCatalogService!.GetCurrentTheme();
  const [messages, setMessages] = useState<Array<IChatMessage>>();
  const [message, setMessage] = useState('');
  const [typing, setTyping] = useState<boolean>(false);
  const [restartDialogVisible, setRestartDialogVisible] = useState<boolean>(false);
  const [restarting, setRestarting] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [documents, setDocuments] = useState<IPickerDocument[]>([]);
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const dialogService = useContext(DialogServiceContext);
  const [hideUploadDialog, setHideUploadDialog] = useState<boolean>(true);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | undefined>(
    undefined
  );

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' && (event.shiftKey || event.ctrlKey)) {
      event.preventDefault();
      setMessage(message + '\n');
    } else if (event.key === 'Enter') {
      event.preventDefault();
      sendUserMessage();
    }
  };

  const sendUserMessage = async () => {
    if (message.trim() && !typing && getTokenCount(message.trim()) <= maxTokens) {
      const tempMsg = message.trim();
      setMessage('');
      setMessages([
        ...(messages ?? []),
        {
          Id: 'TemporaryUserId',
          Content: tempMsg,
          Role: 'user',
          Feedback: null,
          DateTime: new Date(),
          Attachments: documents.map(doc => {
            return {
              Id: doc.id,
              FileName: doc.name,
              Type: doc.type === 'library' ? 'Library' : 'User',
            };
          }),
        },
      ]);
      setTyping(true);
    }
  };

  const sendActivity = async (text: string) => {
    let newMessage: IChatMessage = {
      Id: 'TemporaryAssistantId',
      Content: '',
      Role: 'assistant',
      Feedback: null,
      DateTime: new Date(),
      Attachments: [],
    };
    setMessages([...(messages ?? []), newMessage]);
    chatAppsService!.AskQuestionStream(
      chatApp.Id,
      {
        Prompt: text,
        DocumentIds: documents.filter(doc => doc.type === 'library').map(doc => doc.id),
        UserDocumentIds: documents.filter(doc => doc.type === 'user').map(doc => doc.id),
        UserId: AuthenticationService.Default.Account?.localAccountId!,
      },
      async (data: any) => {
        try {
          const chunk = JSON.parse(data);
          if (chunk.content) {
            newMessage.Content = chunk.content;
            setMessages([...(messages ?? []), newMessage]);
          }
        } catch (error) {
          // Ignore error since the data might not be a valid json
        }
      },
      async () => {
        resumeConversation();
      },
      (error: any) => {
        setTyping(false);
      }
    );
  };

  useEffect(() => {
    if (!typing) {
      //resumeConversation();
    }
  }, [typing]);

  const restartConversation = async () => {
    if (!restarting) {
      setRestarting(true);
      setDocuments([]);
      try {
        await chatService?.DeleteConversation(chatApp.ChannelId);
      } catch {
        /* We don't care about this error, it just means the conversation didn't exist */
      }
      setMessages([]);
      setRestarting(false);
    }
  };

  useEffect(() => {
    if (restarting) {
      setRestartDialogVisible(false);
      setMessages(undefined);
    }
  }, [restarting]);

  const resumeConversation = async (): Promise<void> => {
    let conversation = null;
    try {
      if (isMounted) conversation = await chatService!.GetConversation(chatApp.ChannelId);
    } catch {
      /* We don't care about this error, it just means the conversation didn't exist */
    }
    if (conversation) {
      // If a conversation was already taking place
      setMessages(conversation.History);
    } else {
      // New conversation
      setMessages([]);
    }
  };

  useEffect(() => {
    if (messages && messages.length) {
      const last = messages[messages.length - 1];
      if (last && last.Role === 'user' && last.Id === 'TemporaryUserId') {
        sendActivity(last.Content);
      } else if (last && last.Role === 'assistant' && last.Id !== 'TemporaryAssistantId') {
        setTyping(false);
      }
    }
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    const execute = async () => {
      if (isMounted) await resumeConversation();
    };

    execute();

    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      width: '60%',
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.themePrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    heading: {
      color: theme.palette.themePrimary,
      fontWeight: FontWeights.semibold,
      fontSize: 'inherit',
      margin: '0',
    },
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Restart conversation',
    subText: `If you continue, you will reset your conversation history in ${chatApp.DisplayName}.`,
  };

  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

  const getTokenCount = useCallback(message => message.length, []);

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      let newFiles = new Array<File>();
      for (var i = 0; i < files.length; i++) {
        newFiles.push(files[i]);
      }
      if (
        newFiles.filter(file => {
          const extension = file.name.split('.')[file.name.split('.').length - 1];
          return VALID_UPLOAD_FILE_EXTENSIONS.indexOf(extension.toLowerCase()) === -1;
        }).length > 0
      ) {
        dialogService?.showWarningDialog('Document Upload', 'File extension not supported.');
      } else {
        const CancelToken = axios.CancelToken;
        const cancelTokenSource = CancelToken.source();
        setCancelTokenSource(cancelTokenSource);
        setHideUploadDialog(false);
        setUploadPercentage(0);
        try {
          const newDoc = await userService!.UploadUserDocument(
            files[0],
            cancelTokenSource.token,
            (progressEvent: AxiosProgressEvent) => {
              setUploadPercentage(progressEvent.loaded / progressEvent.total!);
            }
          );
          if (newDoc) {
            setDocuments([
              ...documents,
              {
                id: newDoc?.Id,
                name: newDoc.FileName,
                type: 'user',
              },
            ]);
          }
          //resetState();
          //reloadDocuments();
        } catch (error) {
          const axiosError = error as AxiosError;
          if (axiosError.response?.status === 409 || axiosError.response?.status === 422) {
            dialogService?.showWarningDialog(
              'Document Upload',
              (axiosError.response.data as any).title
            );
          } else if (axiosError.response?.status === 400) {
            const errorMsg = (axiosError.response.data as any).errors[''][0];
            if (errorMsg.toLowerCase().indexOf('request body too large') !== -1) {
              dialogService?.showWarningDialog(
                'Document Upload',
                'The provided file exceeds the allowable size limit. Please ensure that the file size is below 100 megabytes (MB) and try again.'
              );
            } else {
              dialogService?.showWarningDialog('Document Upload', errorMsg);
            }
          }
        }
      }
      event.target.value = '';
      setHideUploadDialog(true);
    }
  };

  const _uploadAttachment = async () => {
    inputRef.current?.click();
  };

  const cancelUpload = async () => {
    cancelTokenSource!.cancel('Operation canceled');
  };

  return (
    <>
      {chatApp ? (
        <>
          <Stack
            grow
            verticalFill
            style={{ minHeight: 0 }}
            horizontalAlign='center'
            className='main-content'
          >
            <Stack
              verticalFill
              grow
              style={{ minHeight: 0, width: '100%' }}
              tokens={{
                padding: '0 1rem',
                maxWidth: '100%',
              }}
            >
              {(messages && (
                <ConversationDebug
                  setMessages={messages => setMessages(messages)}
                  messages={messages}
                  typing={typing}
                />
              )) || (
                <Stack grow verticalAlign='center'>
                  <Spinner size={SpinnerSize.large} />
                </Stack>
              )}
              <Stack>
                <Stack grow className='textarea'>
                  <Stack.Item align='stretch' grow>
                    <TextareaAutosize
                      placeholder={
                        messages ? `Type a message to ${chatApp.DisplayName}...` : 'Loading...'
                      }
                      minRows={2}
                      maxRows={14}
                      value={message}
                      onChange={event => setMessage(event.target.value)}
                      onKeyDown={handleKeyPress}
                      disabled={!messages}
                    ></TextareaAutosize>
                  </Stack.Item>
                  {(chatApp.EnableAttachments || chatApp.EnableLibraryReferences) && (
                    <Stack.Item>
                      <ChatFiles
                        documents={documents}
                        chatApp={chatApp}
                        onChange={documents => {
                          setDocuments(documents.slice(0, 5));
                        }}
                        onRemove={document =>
                          setDocuments(
                            documents.filter(
                              doc => !(doc.id === document.id && doc.type === document.type)
                            )
                          )
                        }
                      />
                    </Stack.Item>
                  )}
                  <Stack horizontal grow>
                    <Stack.Item
                      grow
                      className={classNames(
                        'token-count',
                        getTokenCount(message) > maxTokens && 'error'
                      )}
                    >
                      {getTokenCount(message) > maxTokens && (
                        <FontAwesomeIcon
                          title={'Too many characters in message.'}
                          icon={faWarning}
                        />
                      )}{' '}
                      <span>{getTokenCount(message)}</span>
                      {` / ${maxTokens}`}{' '}
                    </Stack.Item>
                    <Stack.Item align='end' shrink>
                      {showDescription && (
                        <button
                          type='button'
                          title='Description'
                          onClick={() => setShowDisclaimer(true)}
                        >
                          <FontAwesomeIcon icon={faQuestion} />
                        </button>
                      )}

                      {chatApp.EnableAttachments && (
                        <>
                          <button
                            type='button'
                            title='Upload attachment'
                            onClick={_uploadAttachment}
                          >
                            <FontAwesomeIcon icon={faPaperclip} />
                          </button>
                          <input
                            ref={inputRef}
                            type='file'
                            multiple={false}
                            accept={VALID_UPLOAD_FILE_EXTENSIONS.map(ext => `.${ext}`).join(',')}
                            hidden={true}
                            onChange={onFileChange}
                          />
                        </>
                      )}
                      <button
                        type='button'
                        title='Restart conversation'
                        onClick={() => setRestartDialogVisible(true)}
                      >
                        <FontAwesomeIcon icon={faArrowsRotate} />
                      </button>
                      <button
                        disabled={typing}
                        type='button'
                        title='Send message'
                        onClick={sendUserMessage}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </button>
                    </Stack.Item>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Dialog
            hidden={!restartDialogVisible}
            onDismiss={() => setRestartDialogVisible(false)}
            dialogContentProps={dialogContentProps}
            modalProps={{
              isBlocking: true,
            }}
          >
            <DialogFooter>
              <PrimaryButton onClick={restartConversation} text='Yes' />
              <DefaultButton onClick={() => setRestartDialogVisible(false)} text='No' />
            </DialogFooter>
          </Dialog>
          <Modal
            isOpen={showDisclaimer}
            onDismiss={() => setShowDisclaimer(false)}
            isBlocking={false}
            containerClassName={contentStyles.container}
          >
            <div className={contentStyles.header}>
              <h2 className={contentStyles.heading}>{chatApp.DisplayName}</h2>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel='Close popup modal'
                onClick={() => setShowDisclaimer(false)}
              />
            </div>
            <div className={contentStyles.body}>
              <ReactMarkdown linkTarget={'_blank'}>{chatApp.Description}</ReactMarkdown>
            </div>
          </Modal>
          <Dialog
            hidden={hideUploadDialog}
            onDismiss={() => setHideUploadDialog(true)}
            modalProps={{
              isBlocking: true,
              styles: { main: { maxWidth: 450 } },
            }}
            dialogContentProps={{
              showCloseButton: false,
              type: DialogType.normal,
              title: 'Document Upload',
            }}
          >
            {(uploadPercentage < 1 && (
              <ProgressIndicator description='Uploading...' percentComplete={uploadPercentage} />
            )) || <ProgressIndicator description='Processing...' />}
            {uploadPercentage < 1 && (
              <DialogFooter>
                <PrimaryButton
                  disabled={uploadPercentage === 1}
                  onClick={cancelUpload}
                  text='Cancel'
                />
              </DialogFooter>
            )}
          </Dialog>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ApplicationChatDebug;
