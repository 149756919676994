import { FC, createContext, useContext } from 'react';
import { ApiHttpServiceContext } from './ApiHttpService';
import { CacheServiceContext } from './CacheService';
import IChatApp, { LlmModel } from '../../Models/API/IChatApp';
import IChatAppPermission from '../../Models/API/IChatAppPermission';
import { IChatMessage } from '../../Models/API/IConversation';
import IAgentApp from '../../Models/API/IAgentApp';
import ILibrary from '../../Models/API/ILibrary';
import { INewLibrary } from './LibraryService';
import { AxiosProgressEvent, CancelToken } from 'axios';
import IUserPermission, { PermissionType } from '../../Models/API/IUserPermission';
import IDocument from '../../Models/API/IDocument';
import IChatAppHierarchy from '../../Models/API/IChatAppHierarchy';

export interface INewChatApp {
  Model?: LlmModel;
  DisplayName?: string;
  Description?: string;
  LibraryIds?: number[];
  AppCatalogId?: number;
  SystemPrompt?: string;
  NoAnswerMessage?: string;
  MaxTokens?: number;
  ScoreThreshold?: number;
  Temperature?: number;
  TopP?: number;
  PresencePenalty?: number;
  FrequencyPenalty?: number;
  AllowModelSources?: boolean;
  TopResults?: number;
  EnableHybridSearch?: boolean;
  EnableDensitySearch?: boolean;
  EnableModelReRank?: boolean;
  EnableLibraryReferences?: boolean;
  EnableAttachments?: boolean;
  ConversationTimeout?: number;
  Disclaimer?: string | null;
  EnableAnalytics: boolean;
  HideSources: boolean;
}

export interface IChatAppPrompt {
  Prompt: string;
  DocumentIds: number[];
  UserDocumentIds: number[];
  UserId: string;
}

export interface IChatAppService {
  GetAll(): Promise<IChatApp[] | null>;
  Get(chatAppId: number, throwError?: boolean): Promise<IChatApp | null>;
  Update(chatAppId: number, chatApp: INewChatApp): Promise<IChatApp | null>;
  Delete(chatAppId: number): Promise<void | null>;
  Create(chatApp: INewChatApp): Promise<IChatApp | null>;
  AskQuestion(
    chatAppId: number,
    chatPrompt: IChatAppPrompt,
    throwError?: boolean
  ): Promise<any | null>;
  AskQuestionStream(
    chatAppId: number,
    chatPrompt: IChatAppPrompt,
    onDataReceived: (data: any) => void,
    onCompleted: () => void,
    onError: (error: any) => void,
    cancelToken?: CancelToken | undefined,
    throwError?: boolean
  ): void;
  PublishChatApp(chatAppId: number, publish: boolean): Promise<void>;
  GetChatAgents(chatAppId: number): Promise<IAgentApp[] | null>;
  AddChatAgent(chatAppId: number, agentId: number): Promise<void | null>;
  DeleteChatAgent(chatAppId: number, agentId: number): Promise<void | null>;
  GetChatAppForLink(chatAppId: number): Promise<IChatApp[] | null>;
  GetChatAppHierarchy(chatAppId: number): Promise<IChatAppHierarchy[] | null>;
  GetChatAppChats(chatAppId: number): Promise<IChatApp[] | null>;
  AddChatAppChat(chatAppId: number, chatId: number): Promise<void | null>;
  DeleteChatAppChat(chatAppId: number, chatId: number): Promise<void | null>;
  OverrideCatalogPermissions(chatAppId: number, overrideCatalogPermissions: boolean): Promise<void>;
  CreatePermission(
    chatAppId: number,
    principalId: string,
    type: PermissionType
  ): Promise<IChatAppPermission | null>;
  GetPermissions(chatAppId: number): Promise<IChatAppPermission[] | null>;
  UpdatePermission(
    chatAppId: number,
    principalId: string,
    type: PermissionType
  ): Promise<IChatAppPermission | null>;
  DeletePermission(catalogId: number, principalId: string): Promise<void | null>;
  CheckUserPermissions(principalId: string, chatAppId: number): Promise<IUserPermission[] | null>;
  GetChatAppLibraries(chatAppId: number): Promise<ILibrary[] | null>;
  CreateChatAppLibrary(chatAppId: number, library: INewLibrary): Promise<ILibrary | null>;
  ExportChatAppLibraries(chatAppId: number, libraries: number[]): Promise<any | null>;
  ImportChatAppLibraries(
    chatAppId: number,
    file: File,
    cancelToken?: CancelToken | undefined,
    onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void) | undefined
  ): Promise<IDocument | null>;
  GetDocuments(chatAppId: number): Promise<IDocument[] | null>;
}

export const chatAppServiceContext = createContext<IChatAppService | undefined>(undefined);

const ChatAppService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);
  const cacheService = useContext(CacheServiceContext);

  const controller: string = 'chatapps';

  const chatAppService: IChatAppService = {
    async GetAll() {
      return await apiHttpService!.Get<IChatApp[]>(`${controller}`);
    },
    async Get(chatAppId: number, throwError: boolean = false) {
      return await apiHttpService!.Get<IChatApp>(
        `${controller}/${chatAppId}`,
        {},
        false,
        throwError
      );
    },
    async Update(chatAppId: number, chatApp: INewChatApp) {
      cacheService!.ClearCache(`${controller}/${chatApp}`);
      return await apiHttpService!.Put<IChatApp>(`${controller}/${chatAppId}`, chatApp);
    },
    async Delete(chatAppId: number) {
      return await apiHttpService!.Delete<void>(`${controller}/${chatAppId}`);
    },
    async Create(chatApp: INewChatApp) {
      return await apiHttpService!.Post<IChatApp>(`${controller}`, chatApp);
    },
    async AskQuestion(chatAppId: number, chatPrompt: IChatAppPrompt, throwError?: boolean) {
      return await apiHttpService!.Post<IChatMessage>(
        `${controller}/${chatAppId}/ask`,
        chatPrompt,
        {},
        undefined,
        undefined,
        throwError
      );
    },
    async AskQuestionStream(
      chatAppId: number,
      chatPrompt: IChatAppPrompt,
      onDataReceived: (data: any) => void,
      onCompleted: () => void,
      onError: (error: any) => void,
      cancelToken?: CancelToken | undefined,
      throwError?: boolean
    ) {
      await apiHttpService!.PostAndGetStream(
        `${controller}/${chatAppId}/ask/stream`,
        onDataReceived,
        onCompleted,
        onError,
        chatPrompt,
        {},
        cancelToken,
        throwError
      );
    },
    async PublishChatApp(chatAppId: number, publish: boolean) {
      await apiHttpService!.Post<void>(`${controller}/${chatAppId}/publish?publish=${publish}`);
    },
    async GetChatAgents(chatAppId: number): Promise<IAgentApp[] | null> {
      return await apiHttpService!.Get<IAgentApp[]>(`${controller}/${chatAppId}/agents`);
    },
    async AddChatAgent(chatAppId: number, agentId: number): Promise<void | null> {
      return await apiHttpService!.Post<void>(`${controller}/${chatAppId}/agents/${agentId}`);
    },
    async DeleteChatAgent(chatAppId: number, agentId: number): Promise<void | null> {
      return await apiHttpService!.Delete<void>(`${controller}/${chatAppId}/agents/${agentId}`);
    },
    async GetChatAppForLink(chatAppId: number): Promise<IChatApp[] | null> {
      return await apiHttpService!.Get<IChatApp[]>(`${controller}/${chatAppId}/chatapps/available`);
    },
    async GetChatAppHierarchy(chatAppId: number): Promise<IChatAppHierarchy[] | null> {
      return await apiHttpService!.Get<IChatAppHierarchy[]>(
        `${controller}/${chatAppId}/chatapps/hierarchy`
      );
    },
    async GetChatAppChats(chatAppId: number): Promise<IChatApp[] | null> {
      return await apiHttpService!.Get<IChatApp[]>(`${controller}/${chatAppId}/chatapps`);
    },
    async AddChatAppChat(chatAppId: number, chatId: number): Promise<void | null> {
      return await apiHttpService!.Post<void>(`${controller}/${chatAppId}/chatapps/${chatId}`);
    },
    async DeleteChatAppChat(chatAppId: number, chatId: number): Promise<void | null> {
      return await apiHttpService!.Delete<void>(`${controller}/${chatAppId}/chatapps/${chatId}`);
    },
    async OverrideCatalogPermissions(chatAppId: number, overrideCatalogPermissions: boolean) {
      await apiHttpService!.Post<void>(
        `${controller}/${chatAppId}/overrideCatalogPermissions?overrideCatalogPermissions=${overrideCatalogPermissions}`
      );
    },
    async CreatePermission(chatAppId: number, principalId: string, type: PermissionType) {
      return await apiHttpService!.Post<IChatAppPermission>(
        `${controller}/${chatAppId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
    async GetPermissions(chatAppId: number) {
      return await apiHttpService!.Get<IChatAppPermission[]>(
        `${controller}/${chatAppId}/permissions`
      );
    },
    async DeletePermission(chatAppId: number, principalId: string) {
      await apiHttpService!.Delete<IChatAppPermission>(
        `${controller}/${chatAppId}/permissions/${principalId}`
      );
    },
    async CheckUserPermissions(principalId: string, chatAppId: number) {
      return await apiHttpService!.Get<IUserPermission[]>(
        `${controller}/${chatAppId}/permissions/${principalId}`
      );
    },
    async UpdatePermission(chatAppId: number, principalId: string, type: PermissionType) {
      return await apiHttpService!.Put<IChatAppPermission>(
        `${controller}/${chatAppId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
    async GetChatAppLibraries(chatAppId: number): Promise<ILibrary[] | null> {
      return await apiHttpService!.Get<ILibrary[]>(`${controller}/${chatAppId}/libraries`);
    },
    async CreateChatAppLibrary(chatAppId: number, library: INewLibrary) {
      return await apiHttpService!.Post<ILibrary>(`${controller}/${chatAppId}/libraries`, library);
    },
    async ExportChatAppLibraries(chatAppId: number, libraries: number[]): Promise<any | null> {
      const response = await apiHttpService!.Get<any>(
        `${controller}/${chatAppId}/artifacts-export?libraryIds=${libraries.join(',')}`,
        undefined
      );

      if (!response) {
        return null;
      }

      const contentDisposition = response.headers['content-disposition'];
      let filename = 'exported-libraries.zip'; // Default filename

      if (contentDisposition && contentDisposition.includes('attachment')) {
        const filenameUtf8Match = /filename\*\s*=\s*UTF-8''(.+?)(;|$)/.exec(contentDisposition);
        const filenameMatch = /filename\s*=\s*"(.+?)"/.exec(contentDisposition);

        if (filenameUtf8Match) {
          filename = decodeURIComponent(filenameUtf8Match[1]); // Handle UTF-8 filenames
        } else if (filenameMatch) {
          filename = filenameMatch[1]; // Fallback to standard filename
        }
      }

      return {
        blob: response.data,
        filename,
      };
    },
    async ImportChatAppLibraries(
      chatAppId: number,
      file: File,
      cancelToken: CancelToken | undefined = undefined,
      onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined = undefined
    ) {
      var formData = new FormData();

      formData.append('file', file);

      return await apiHttpService!.Post<IDocument>(
        `${controller}/${chatAppId}/artifacts-import`,
        formData,
        {},
        cancelToken,
        onUploadProgress,
        true
      );
    },
    async GetDocuments(chatAppId: number) {
      return await apiHttpService!.Get<IDocument[]>(
        `${controller}/${chatAppId}/documents`,
        {},
        true
      );
    },
  };

  return (
    <chatAppServiceContext.Provider value={chatAppService}>
      {children}
    </chatAppServiceContext.Provider>
  );
};

export default ChatAppService;
