import { FC, useEffect, useState } from 'react';
import {
  AgentAppFunctionParameters,
  AgentAppFunctionProperty,
  AuthenticationType,
  IAgentAppFunction,
  IAgentPropertyType,
} from '../../../../../../Models/API/IAgentAppFunction';
import { INewAgentAppFunction } from '../../../../../../Services/API/AgentAppService';
import {
  Dropdown,
  IDropdownOption,
  Pivot,
  PivotItem,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import FunctionFormProperties from './FunctionsFormProperties';
import FunctionFormAuthProfile from './FunctionsFormAuthProfile';
import { useOutletContext } from 'react-router-dom';
import IAgentApp from '../../../../../../Models/API/IAgentApp';
import { useId } from '@fluentui/react-hooks';
import AuthenticationService from '../../../../../../Services/AuthenticationService';
import FunctionFormHeaders from './FunctionsFormHeaders';

export interface IFunctionsFormProps {
  agentFunction: IAgentAppFunction | INewAgentAppFunction;
  setAgentFunction(agentFunction: IAgentAppFunction | INewAgentAppFunction): void;
}

export interface Property {
  internalId: number;
  name: string;
  type: IAgentPropertyType;
  description: string;
  required: boolean;
}

export const FieldTypes: IDropdownOption[] = [
  { key: 'Get', text: 'Get' },
  { key: 'Post', text: 'Post' },
  { key: 'Put', text: 'Put' },
  { key: 'Delete', text: 'Delete' },
];

const FunctionForm: FC<IFunctionsFormProps> = ({ agentFunction, setAgentFunction }) => {
  const { agentApp } = useOutletContext<{
    agentApp: IAgentApp;
  }>();

  const userId = AuthenticationService.Default.Account?.localAccountId!;

  const httpMethod = useId('httpMethod');

  const [properties, setProperties] = useState<Property[]>([]);
  const [propertiesDirty, setPropertiesDirty] = useState<boolean>(false);

  const [currentKey, setCurrentKey] = useState<string>('properties');

  useEffect(() => {
    if (agentFunction && agentFunction.Parameters) {
      const functionProperties = agentFunction.Parameters.Properties;
      const requiredProperties = agentFunction.Parameters.Required;
      const props: Property[] = [];
      if (functionProperties) {
        let counter = 0;
        for (const [key, value] of functionProperties) {
          props.push({
            internalId: ++counter,
            name: key,
            description: value.Description,
            type: value.Type,
            required: requiredProperties.includes(key),
          });
        }
      }
      setProperties(props);
    }
  }, [agentFunction]);

  const mapProperties = (): IAgentAppFunction | INewAgentAppFunction => {
    const agentProperties = new Map<string, AgentAppFunctionProperty>();

    properties.forEach(x => {
      agentProperties.set(x.name, {
        Type: x.type,
        Description: x.description,
      });
    });

    const requiredProperties = properties.filter(x => x.required).map(x => x.name);

    const parameters: AgentAppFunctionParameters = {
      ...agentFunction.Parameters!,
      Properties: agentProperties,
      Required: requiredProperties,
    };

    return { ...agentFunction, Parameters: parameters };
  };

  const getExampleValue = (): string => {
    const exampleValues: { [key in IAgentPropertyType]: any } = {
      string: 'string',
      integer: 0,
      double: 0.0,
      boolean: true,
    };

    const argumentsObject = agentFunction.Parameters?.Properties
      ? Object.fromEntries(
          Array.from(agentFunction.Parameters.Properties.entries()).map(([key, property]) => {
            return [key, exampleValues[property.Type] ?? 'UnknownType'];
          })
        )
      : {};

    const toQueryString = (obj: { [key: string]: any }) =>
      Object.entries(obj)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

    switch (agentFunction.HttpMethod) {
      case 'Get':
      case 'Delete':
        return `${agentFunction.ActionUrl}?callerId=${encodeURIComponent(
          userId
        )}&agentName=${encodeURIComponent(agentApp.InternalName)}&functionName=${encodeURIComponent(
          agentFunction.DisplayName!
        )}&lastChatApps=${JSON.stringify([{ name: 'ChatJTI', id: 1 }])}&${toQueryString(
          argumentsObject
        )}`;
      case 'Post':
      case 'Put':
        return JSON.stringify(
          {
            callerId: userId,
            agentName: agentApp.InternalName,
            functionName: agentFunction.DisplayName,
            lastChatApps: [{ name: 'ChatJTI', id: 1 }],
            arguments: argumentsObject,
          },
          null,
          2
        );
      default:
        return '';
    }
  };

  useEffect(() => {
    if (propertiesDirty) {
      setPropertiesDirty(false);
      setAgentFunction(mapProperties());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties]);

  return (
    <>
      {agentFunction !== undefined && (
        <Stack style={{ marginTop: 20 }} tokens={{ childrenGap: 20 }}>
          <Stack.Item>
            <TextField
              required={true}
              label='Display Name'
              value={agentFunction!.DisplayName}
              onChange={(evt, newValue) => {
                setAgentFunction({ ...agentFunction!, DisplayName: newValue! });
              }}
            />
            <Text style={{ marginTop: 4 }} variant='xSmall' block>
              Minimum 5 characters.
            </Text>
            <Text variant='xSmall' block>
              Can only contain letters, numbers and spaces.
            </Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              required={true}
              label='Description'
              multiline
              rows={3}
              resizable={false}
              value={agentFunction!.Description}
              onChange={(evt, newValue) => {
                setAgentFunction({ ...agentFunction!, Description: newValue! });
              }}
            />
            <Text variant='xSmall'>Minimum 20 characters</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label='Return value'
              value={agentFunction!.ReturnValue}
              onChange={(evt, newValue) => {
                setAgentFunction({ ...agentFunction!, ReturnValue: newValue! });
              }}
            ></TextField>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              <Stack.Item styles={{ root: { width: '25%' } }}>
                <Dropdown
                  label='HTTP Method'
                  id={httpMethod}
                  selectedKey={agentFunction?.HttpMethod || 'Post'}
                  onChange={(evt, option, index) => {
                    setAgentFunction({
                      ...agentFunction!,
                      HttpMethod: option!.key as any,
                    });
                  }}
                  placeholder='Select a type'
                  options={FieldTypes}
                />
              </Stack.Item>
              <Stack.Item styles={{ root: { width: '100%' } }}>
                <TextField
                  label='Action Url'
                  value={agentFunction!.ActionUrl}
                  onChange={(evt, newValue) => {
                    setAgentFunction({ ...agentFunction!, ActionUrl: newValue! });
                  }}
                ></TextField>
              </Stack.Item>
            </Stack>
          </Stack.Item>

          {(agentApp.PermissionType === 'Write' ||
            agentApp.PermissionType === 'FullControl' ||
            agentApp.PermissionType === 'Owner') && (
            <Pivot
              aria-label='Agent App Options'
              selectedKey={currentKey}
              onLinkClick={item => setCurrentKey(item?.props.itemKey!)}
            >
              <PivotItem itemKey='properties' headerText='Properties' itemIcon='Parameter'>
                <FunctionFormProperties
                  properties={properties}
                  setProperties={setProperties}
                  setPropertiesDirty={setPropertiesDirty}
                />
              </PivotItem>
              <PivotItem itemKey='authentication' headerText='Authentication' itemIcon='Lock'>
                <FunctionFormAuthProfile
                  agentFunction={agentFunction}
                  setAgentFunction={setAgentFunction}
                  actionUrl={agentFunction.ActionUrl!}
                  httpMethod={agentFunction.HttpMethod!}
                />
              </PivotItem>

              <PivotItem itemKey='headers' headerText='Headers' itemIcon='Header'>
                <FunctionFormHeaders
                  agentFunction={agentFunction}
                  setAgentFunction={setAgentFunction}
                />
              </PivotItem>

              <PivotItem itemKey='example' headerText='Example' itemIcon='FileTemplate'>
                <TextField
                  label='Example'
                  readOnly
                  multiline
                  rows={
                    agentFunction.HttpMethod === 'Get' || agentFunction.HttpMethod === 'Delete'
                      ? 5
                      : 13
                  }
                  min={1}
                  value={getExampleValue()}
                  resizable={false}
                />
              </PivotItem>
            </Pivot>
          )}
        </Stack>
      )}
    </>
  );
};

export default FunctionForm;
