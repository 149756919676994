import React, { FC, useContext, useState } from 'react';
import {
  Stack,
  Dropdown,
  TextField,
  IDropdownOption,
  PrimaryButton,
  Callout,
  DirectionalHint,
} from '@fluentui/react';
import {
  AuthenticationType,
  IAgentAppFunction,
  IAgentFunctionHttpMethod,
  ITestAuthResponse,
  OAuthParameters,
} from '../../../../../../Models/API/IAgentAppFunction';
import {
  agentAppServiceContext,
  INewAgentAppFunction,
  ITestAuthentication,
} from '../../../../../../Services/API/AgentAppService';

const SecretValue = '********************';

export interface IFunctionFormAuthProfileProps {
  agentFunction: IAgentAppFunction | INewAgentAppFunction;
  setAgentFunction(agentFunction: IAgentAppFunction | INewAgentAppFunction): void;
  actionUrl: string;
  httpMethod: IAgentFunctionHttpMethod;
}

const FunctionFormAuthProfile: FC<IFunctionFormAuthProfileProps> = ({
  agentFunction,
  setAgentFunction,
  actionUrl,
  httpMethod,
}) => {
  const agentAppsService = useContext(agentAppServiceContext);

  const [authType, setAuthType] = useState<AuthenticationType | null>(
    agentFunction.AuthenticationType || AuthenticationType.PowerAutomate
  );

  const [isTestingAuth, setIsTestingAuth] = useState<boolean>(false);
  const [testAuthResponse, setTestAuthResponse] = useState<ITestAuthResponse | null>(null);

  const [showCallout, setShowCallout] = useState(false);

  const testAuth = async () => {
    setIsTestingAuth(true);
    const testAuthObj: ITestAuthentication = {
      ActionUrl: actionUrl,
      HttpMethod: httpMethod,
      AuthenticationType: agentFunction.AuthenticationType,
      OAuthParameters: agentFunction.OAuthParameters,
      Headers: agentFunction.Headers,
    };

    var response = await agentAppsService?.TestAuthentication(testAuthObj);
    setIsTestingAuth(false);

    if (response) {
      setTestAuthResponse(response);
      setShowCallout(true);
      setTimeout(() => {
        setTestAuthResponse(null);
      }, 5000);
    }
  };

  const handleAuthTypeChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    if (option) {
      const selectedAuthType = option.key as AuthenticationType;
      setAuthType(selectedAuthType);
      if (selectedAuthType === AuthenticationType.OAuthApplication) {
        setAgentFunction({
          ...agentFunction,
          AuthenticationType: AuthenticationType.OAuthApplication,
          OAuthParameters: {
            ClientId: '',
            ClientSecret: '',
            TokenEndpoint: '',
            Scope: '',
          } as OAuthParameters,
        });
      } else if (selectedAuthType === AuthenticationType.AuthenticationHeaders) {
        setAgentFunction({
          ...agentFunction,
          AuthenticationType: AuthenticationType.AuthenticationHeaders,
        });
      } else if (selectedAuthType === AuthenticationType.PowerAutomate) {
        setAgentFunction({
          ...agentFunction,
          AuthenticationType: AuthenticationType.PowerAutomate,
        });
      }
    }
  };

  const updateOAuthParameters = (field: keyof OAuthParameters, value: string) => {
    if (agentFunction.AuthenticationType === AuthenticationType.OAuthApplication) {
      setAgentFunction({
        ...agentFunction,
        OAuthParameters: {
          ...agentFunction.OAuthParameters,
          [field]: value,
        } as OAuthParameters,
      });
    }
  };

  const showTestButton = () => {
    if (agentFunction.AuthenticationType === AuthenticationType.PowerAutomate) return false;

    if (agentFunction.AuthenticationType === AuthenticationType.OAuthApplication) {
      if (!agentFunction.OAuthParameters) return false;

      if (!agentFunction.OAuthParameters.TokenEndpoint) return false;
      if (!agentFunction.OAuthParameters.ClientId) return false;
      if (!agentFunction.OAuthParameters.ClientSecret) return false;
      if (!agentFunction.OAuthParameters.Scope) return false;

      return agentFunction.OAuthParameters.ClientSecret !== SecretValue;
    }

    if (agentFunction.AuthenticationType === AuthenticationType.AuthenticationHeaders) {
      if (!agentFunction.Headers) return false;
      const hasSecretValue = agentFunction.Headers.some(header => header.Value === SecretValue);
      if (hasSecretValue) return false;
      return true;
    }

    return true;
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      {actionUrl && httpMethod && (
        <Stack.Item>
          {showTestButton() && (
            <Stack horizontal horizontalAlign='end'>
              <Stack.Item>
                <PrimaryButton
                  text={
                    isTestingAuth
                      ? 'Testing'
                      : testAuthResponse === null
                      ? 'Test Authentication'
                      : testAuthResponse.Success
                      ? 'Success'
                      : 'Failed'
                  }
                  onClick={() => testAuth()}
                  styles={{
                    root: {
                      padding: '4px 8px',
                      height: '32px',
                      fontSize: '14px',
                      marginTop: '1rem',
                      alignSelf: 'flex-end',
                    },
                  }}
                  id='testAuthButton'
                />
                {showCallout && testAuthResponse !== null && !testAuthResponse.Success && (
                  <Callout
                    target='#testAuthButton'
                    onDismiss={() => setShowCallout(false)}
                    setInitialFocus
                    directionalHint={DirectionalHint.topCenter}
                  >
                    <div style={{ padding: '8px' }}>
                      <span>{testAuthResponse.ErrorMessage}</span>
                    </div>
                  </Callout>
                )}
              </Stack.Item>
            </Stack>
          )}
        </Stack.Item>
      )}
      <Stack.Item>
        <Dropdown
          placeholder='Select Authentication Type'
          label='Authentication Type'
          selectedKey={authType || undefined}
          options={[
            { key: AuthenticationType.PowerAutomate, text: 'Power Automate' },
            {
              key: AuthenticationType.OAuthApplication,
              text: 'OAuth Authentication (Client Credentials)',
            },
            { key: AuthenticationType.AuthenticationHeaders, text: 'Header Authentication' },
          ]}
          onChange={handleAuthTypeChange}
          styles={{
            label: {
              marginBottom: '0.3rem',
              fontSize: '1rem',
            },
          }}
        />
      </Stack.Item>

      {authType === AuthenticationType.OAuthApplication && (
        <Stack.Item>
          <Stack tokens={{ childrenGap: 10 }}>
            <TextField
              label='Token Endpoint'
              value={(agentFunction.OAuthParameters as OAuthParameters)?.TokenEndpoint || ''}
              onChange={(e, value) => updateOAuthParameters('TokenEndpoint', value || '')}
            />
            <TextField
              label='Client ID'
              value={(agentFunction.OAuthParameters as OAuthParameters)?.ClientId || ''}
              onChange={(e, value) => updateOAuthParameters('ClientId', value || '')}
            />

            <TextField
              label='Client Secret'
              type={'password'}
              value={(agentFunction.OAuthParameters as OAuthParameters)?.ClientSecret || ''}
              onChange={(e, value) => updateOAuthParameters('ClientSecret', value || '')}
            />
            <TextField
              label='Scope'
              value={(agentFunction.OAuthParameters as OAuthParameters)?.Scope || ''}
              onChange={(e, value) => updateOAuthParameters('Scope', value || '')}
            />
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default FunctionFormAuthProfile;
