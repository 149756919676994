import React, { FC, useContext, useEffect, useState } from 'react';
import './Settings.scss';
import {
  INavLink,
  INavLinkGroup,
  INavStyles,
  Nav,
  Spinner,
  SpinnerSize,
  Stack,
} from '@fluentui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SettingsServiceContext } from '../../Services/API/SettingsService';
import ISettings from '../../Models/API/ISettings';
import ConfigurationService from '../../Services/ConfigurationService';

const navStyles: Partial<INavStyles> = {
  root: {
    width: 208,
    boxSizing: 'border-box',
    overflowY: 'auto',
  },
};

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: 'Chat',
        url: 'chat',
        key: 'chat',
      },
      {
        name: 'Chat JTI',
        url: 'chatjti',
        key: 'chatjti',
      },
      {
        name: 'Prompts',
        url: 'prompts',
        key: 'prompts',
      },
      {
        name: 'Search',
        url: 'search',
        key: 'search',
      },
      {
        name: 'Training',
        url: 'training',
        key: 'training',
      },
      {
        name: 'Analytics',
        url: 'analytics',
        key: 'analytics',
      },
      {
        name: 'Artifacts',
        url: 'artifacts',
        key: 'artifacts',
      },
    ],
  },
];

const Settings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const [settings, setSettings] = useState<ISettings | null>(null);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  useEffect(() => {
    const execute = async () => {
      const reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
    };
    execute();
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - Settings`;
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const route = location.pathname.split('/')[location.pathname.split('/').length - 1];

  const _onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    ev?.preventDefault();
    navigate(item?.url!, {
      relative: 'route',
    });
  };

  return (
    <Stack grow verticalFill style={{ minHeight: 0 }} horizontalAlign='center'>
      <Stack
        verticalFill
        grow
        style={{ minHeight: 0, width: '100%' }}
        tokens={{
          childrenGap: 20,
        }}
      >
        <Stack horizontal verticalFill>
          <Stack.Item shrink>
            <Nav
              onLinkClick={_onLinkClick}
              selectedKey={route}
              ariaLabel='Nav basic example'
              styles={navStyles}
              groups={navLinkGroups}
            />
          </Stack.Item>
          <Stack.Item grow shrink style={{ padding: 10, overflowY: 'scroll' }}>
            {(settings && <Outlet />) || <Spinner size={SpinnerSize.large} />}
          </Stack.Item>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Settings;
