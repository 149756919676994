import React from 'react';
import { Stack, TextField, CommandBarButton, IIconProps, Text, Checkbox } from '@fluentui/react';
import {
  AuthenticationType,
  IAgentAppFunction,
} from '../../../../../../Models/API/IAgentAppFunction';
import styles from './FunctionsFormAuthProfile.module.scss';
import { INewAgentAppFunction } from '../../../../../../Services/API/AgentAppService';

const addIcon: IIconProps = { iconName: 'CircleAddition' };

export interface IFunctionFormHeadersProps {
  agentFunction: IAgentAppFunction | INewAgentAppFunction;
  setAgentFunction(agentFunction: IAgentAppFunction | INewAgentAppFunction): void;
}

const FunctionFormHeaders: React.FC<IFunctionFormHeadersProps> = ({
  agentFunction,
  setAgentFunction,
}) => {
  const updateHeaderParameters = (
    index: number,
    field: keyof { Key: string; Value: string; IsSecret: boolean },
    value: string | boolean
  ) => {
    const updatedHeaders = [...agentFunction!.Headers!];
    updatedHeaders[index] = {
      ...updatedHeaders[index],
      [field]: value,
    };

    setAgentFunction({
      ...agentFunction,
      Headers: updatedHeaders,
    });
  };

  const addHeader = () => {
    setAgentFunction({
      ...agentFunction,
      Headers: [...(agentFunction.Headers ?? []), { Key: '', Value: '', IsSecret: false }],
    });
  };

  const removeHeader = (index: number) => {
    const updatedHeaders = [...agentFunction.Headers!];
    updatedHeaders.splice(index, 1);
    setAgentFunction({
      ...agentFunction,
      Headers: updatedHeaders,
    });
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack.Item>
        <Text variant='large'>Headers</Text>
        {agentFunction?.Headers?.map((header, index) => (
          <Stack
            key={index}
            horizontal
            tokens={{ childrenGap: 10 }}
            className={styles.propContainer}
          >
            <TextField
              label='Key'
              value={header.Key}
              onChange={(e, value) => updateHeaderParameters(index, 'Key', value || '')}
            />
            <TextField
              label='Value'
              type={header.IsSecret ? 'password' : 'text'}
              value={header.Value}
              onChange={(e, value) => updateHeaderParameters(index, 'Value', value || '')}
            />
            <Checkbox
              className={styles.isSecret}
              id={`isSecretId-${index}`}
              checked={header.IsSecret}
              onRenderLabel={() => <span style={{ display: 'block' }}>Is Secret</span>}
              onChange={(e, checked) => updateHeaderParameters(index, 'IsSecret', checked || false)}
            />
            <CommandBarButton
              className={styles.removeButton}
              iconProps={{ iconName: 'Delete' }}
              onClick={() => removeHeader(index)}
              text='Remove'
            />
          </Stack>
        ))}
      </Stack.Item>
      <Stack.Item>
        <CommandBarButton iconProps={addIcon} text='Add Header' onClick={addHeader} />
      </Stack.Item>
    </Stack>
  );
};

export default FunctionFormHeaders;
