import { FC, createContext, useContext } from 'react';
import { ApiHttpServiceContext } from './ApiHttpService';
import { IArtifactsJobResponse } from '../../Models/API/IArtifactsJobResponse';
import { CancelToken } from 'axios';

export interface IArtifactsService {
  GetJobs(): Promise<IArtifactsJobResponse | null>;
  DownloadArtifact(
    chatAppId: number,
    blobId: string,
    cancelToken: CancelToken | undefined,
    onDownloadProgress: (progressEvent: any) => void
  ): Promise<any | null>;
}

export const artifactsServiceContext = createContext<IArtifactsService | undefined>(undefined);

const ArtifactsService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);

  const controller: string = 'artifacts';

  const artifactsService: IArtifactsService = {
    async GetJobs() {
      console.log('ArtifactsService.GetJobs');

      return await apiHttpService!.Get<IArtifactsJobResponse>(`${controller}/jobs`);
    },
    async DownloadArtifact(
      chatAppId: number,
      blobId: string,
      cancelToken: CancelToken | undefined,
      onDownloadProgress: (progressEvent: any) => void
    ): Promise<any | null> {
      const response = await apiHttpService!.DownloadWithHeaders<any>(
        `chatapps/${chatAppId}/artifacts-download?artifactId=${blobId}`,
        undefined,
        cancelToken,
        onDownloadProgress
      );

      if (!response) {
        return null;
      }

      const contentDisposition = response.headers['content-disposition'];
      let filename = 'exported-libraries.zip'; // Default filename

      if (contentDisposition && contentDisposition.includes('attachment')) {
        const filenameUtf8Match = /filename\*\s*=\s*UTF-8''(.+?)(;|$)/.exec(contentDisposition);
        const filenameMatch = /filename\s*=\s*"(.+?)"/.exec(contentDisposition);

        if (filenameUtf8Match) {
          filename = decodeURIComponent(filenameUtf8Match[1]); // Handle UTF-8 filenames
        } else if (filenameMatch) {
          filename = filenameMatch[1]; // Fallback to standard filename
        }
      }

      return {
        blob: response.data,
        filename,
      };
    },
  };

  return (
    <artifactsServiceContext.Provider value={artifactsService}>
      {children}
    </artifactsServiceContext.Provider>
  );
};

export default ArtifactsService;
